import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./reset.css";
import {
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import "assets/css/material-dashboard-react.css?v=1.8.0";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import api from "./api";

const hist = createBrowserHistory();

const LoadableComponent = (name: string): React.FC<any> => {
  const Component = React.lazy(() => import(`./screens/${name}`));

  return () => (
    <Suspense fallback={<></>}>
      <Component />
    </Suspense>
  );
};

const Protected = (name: string): React.FC<any> => {
  const history = useHistory();

  if (
    !history.location.pathname.includes("admin") &&
    !history.location.pathname.includes("dist")
  )
    return () => <></>;

  const Component = LoadableComponent(name);

  const exp = localStorage.getItem("exp");
  const token = localStorage.getItem("accessToken");

  if (!token || !exp || new Date(Number(exp) * 1000) < new Date()) {
    history.push("/login");
  }

  api.setToken(token as string);

  return (props) => {
    return <Component {...props} />;
  };
};

function App() {
  const location = useLocation();

  return (
    <Fade>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          timeout={{ enter: 300, exit: 300 }}
          classNames={"fade"}
        >
          <Switch>
            <Route
              exact={true}
              path={"/"}
              component={LoadableComponent("LoginScreen")}
            />
            <Route
              exact={true}
              path={"/login"}
              component={LoadableComponent("LoginScreen")}
            />
            <Route
              exact={true}
              path={"/password/forgot"}
              component={LoadableComponent("PasswordScreen")}
            />
            <Route
              exact={true}
              path={"/password/reset/:token"}
              component={LoadableComponent("PasswordResetScreen")}
            />
            <Route
              path="/admin"
              component={Protected("Admin")}
            />
            {/*<Redirect*/}
            {/*  from="/"*/}
            {/*  exact={true}*/}
            {/*  to="/admin"*/}
            {/*/>*/}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Fade>
  );
}

const Fade = styled.div`
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`;

ReactDOM.render(
  <Router history={hist}>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
