export class Api {
  private token: string | undefined;
  private static api: Api | null = null;

  public static getInstance() {
    if (!this.api) this.api = new Api();
    return this.api;
  }

  public async authorize(username: string, password: string) {
    try {
      const response = await fetch(this.url("/login_check"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ username, password }),
      });

      const { token, permissions, firstLogin } = await response.json();

      this.token = token;

      return { token, permissions, firstLogin };
    } catch (e) {
      throw new Error("Failed to fetch: " + e);
    }
  }

  public async fetchResource(resource: string, id: number | string | undefined) {
    return await this.authorizedFetch(this.url(`${resource}${id? id:''}`), {
      method: "GET",
    });
  }

  public async patchResource(resource: string, id: number | string | undefined, data: any) {
    return await this.authorizedFetch(this.url(`${resource}${id? id:''}`), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public async addResource(resource: string, props) {
    return await this.authorizedFetch(this.url(`${resource}`), {
      method: "POST",
      body: JSON.stringify(props),
    });
  }

  public async saveResource(resource: string, props) {
    return await this.authorizedFetch(this.url(`${resource}${props.id}`), {
      method: "PUT",
      body: JSON.stringify(props),
    });
  }

  public async deleteResource(resource: string, id: number) {
    return await this.authorizedFetch(this.url(`${resource}${id}`), {
      method: "DELETE",
    });
  }

  public async fetchResources(resource: string) {
    return await this.authorizedFetch(this.url(`${resource}`), {
      method: "GET",
    });
  }

  public async changeEmail(email: string) {
    return await this.authorizedFetch(this.url("/secure/user/change_email"), {
      body: JSON.stringify({ email }),
    });
  }

  public async updateProductComponents(id: any, data: any) {
    return await this.authorizedFetch(this.url("/product/component/" + id), {
      body: JSON.stringify(data),
      method: "POST",
    });
  }

  public async getProductComponents(id: any) {
    return await this.authorizedFetch(this.url("/product/components/" + id), {
      method: "GET",
    });
  }

  public async getOffers() {
    return await this.authorizedFetch(this.url("/secure/order"), {
      method: "GET",
    });
  }

  public async getUsers() {
    return await this.authorizedFetch(this.url("/secure/user"), {
      method: "GET",
    });
  }

  public async changePassword(password: string, newPassword: string) {
    return await this.authorizedFetch(
      this.url("/secure/user/change_password"),
      {
        body: JSON.stringify({ old: password, new: newPassword }),
      }
    );
  }

  public async updateStatus(id: number, status: number) {
    return await this.authorizedFetch(this.url(`/secure/order/${id}`), {
      method: "POST",
      body: JSON.stringify({ status: status }),
    });
  }

  public async updateUser(id: number, user: any) {
    return await this.authorizedFetch(this.url(`/secure/user/${id}`), {
      method: "POST",
      body: JSON.stringify(user),
    });
  }

  public async createUser(user: any) {
    return await this.authorizedFetch(this.url(`/secure/user`), {
      method: "POST",
      body: JSON.stringify(user),
    });
  }

  public async createCategory(data) {
    return await this.authorizedFetch(this.url(`/attribute/category`), {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  public async editCategory(data) {
    return await this.authorizedFetch(this.url(`/attribute/category`), {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  public async deleteCategory(data) {
    return await this.authorizedFetch(this.url(`/attribute/category`), {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }

  public async deleteAttribute(data) {
    return await this.authorizedFetch(this.url(`/attribute`), {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }

  public async changeDefaultAttribute(data) {
    return await this.authorizedFetch(this.url(`/attribute/default`), {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  public async getCarts() {
    return await this.authorizedFetch(this.url("/cart"), {
      method: "GET",
    });
  }

  public async getContent() {
    return await this.authorizedFetch(this.url("/content"), {
      method: "GET",
    });
  }

  public async getAttributes() {
    return await this.authorizedFetch(this.url("/attribute"), {
      method: "GET",
    });
  }

  public async getSyncStatus() {
    return await this.authorizedFetch(this.url("/product/sync/status"), {
      method: "GET",
    });
  }

  public async startSync() {
    return await this.authorizedFetch(this.url("/product/secure/sync"), {
      method: "GET",
    });
  }

  public async startSyncProduct(products) {
    return await this.authorizedFetch(this.url("/product/sync"), {
      method: "POST",
      body: JSON.stringify(products),
    });
  }

  public async updateAttribute(data) {
    return await this.authorizedFetch(this.url("/attribute"), {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  public async createAttribute(data) {
    return await this.authorizedFetch(this.url("/attribute"), {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  public async updateContent(id: number, content: string) {
    return await this.authorizedFetch(this.url(`/secure/content/${id}`), {
      method: "POST",
      body: JSON.stringify({ value: content }),
    });
  }

  public async addImage(formData: FormData) {
    const response = await this.authorizedFetch(this.url("/product/image"), {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + this.token,
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response;
  }

  public async resetPasswordEmail(email: string) {
    try {
      const response = await fetch(this.url("/user/request_change_password"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ email: email }),
      });

      const token = await response.text();

      return token;
    } catch (e) {
      throw new Error("Failed to fetch: " + e);
    }
  }

  public async resetPasswordToken(token: string, password: string) {
    return await fetch(this.url("/user/change_password_token"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ token: token, password: password }),
    });
  }

  public setToken(token: string) {
    this.token = token;
  }

  public isAuthorized() {
    return !!this.token;
  }

  public url(path: string = "") {
    return process.env.REACT_APP_API_URL + path;
  }

  public authorizedFetch(input: RequestInfo, init?: RequestInit) {
    if (!this.isAuthorized) {
      throw new Error("Not authorized");
    }

    return fetch(input, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
        "Access-Control-Allow-Origin": "*",
      },
      ...init,
    });
  }
}

export default Api.getInstance();
